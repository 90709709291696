{
  "menu": [
    {
      "label": "project",
      "route": "projet",
      "key": "project",
      "isPrimary": true,
      "name": "PROJET"
    },
    { "label": "equipe", "route": "equipe", "key": "team", "isPrimary": true, "name": "EQUIPE" },
    { "label": "sponsor", "route": "sponsor", "key": "sponsor", "name": "SPONSOR" },
    {
      "label": "artistes",
      "route": "artistes",
      "key": "artists",
      "isPrimary": true,
      "name": "ARTISTES"
    },
    { "label": "shop", "route": "shop", "key": "shop", "isPrimary": true, "name": "BOUTIQUE" },
    { "label": "tickets", "route": "tickets", "key": "tickets", "name": "TICKETS" },
    { "label": "club", "route": "club", "key": "club", "name": "CLUB" },
    { "label": "presse", "route": "presse", "key": "press", "name": "PRESSE" },
    { "label": "edition", "route": "edition", "key": "edition", "name": "EDITION" },
    { "label": "contacts", "route": "disclaimer", "key": "contacts", "name": "CONTACTS" }
  ],
  "backToList": "Retour aux produits",
  "persoPlaceHolder": "Nom et/ou numéro",
  "personalize": "Personnalisez votre maillot",
  "buy": "Acheter",
  "soldOut": "Sold out"
}
