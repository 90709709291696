{
  "menu": [
    {
      "label": "progetto",
      "route": "progetto",
      "key": "project",
      "isPrimary": true,
      "name": "PROGETTO"
    },
    { "label": "squadra", "route": "squadra", "key": "team", "isPrimary": true, "name": "SQUADRA" },
    { "label": "sponsor", "route": "sponsor", "key": "sponsor", "name": "SPONSOR" },
    {
      "label": "artisti",
      "route": "artisti",
      "key": "artists",
      "isPrimary": true,
      "name": "ARTISTS"
    },
    { "label": "shop", "route": "shop", "key": "shop", "isPrimary": true, "name": "SHOP" },
    { "label": "tickets", "route": "tickets", "key": "tickets", "name": "TICKETS" },
    { "label": "societa", "route": "societa", "key": "club", "name": "CLUB" },
    { "label": "stampa", "route": "stampa", "key": "press", "name": "STAMPA" },
    { "label": "edizione", "route": "edizione", "key": "edition", "name": "EDIZIONE" },
    { "label": "contatti", "route": "disclaimer", "key": "contacts", "name": "CONTATTI" }
  ],
  "backToList": "Torna ai prodo",
  "persoPlaceHolder": "nome e/o numero",
  "personalize": "Personalizza la tua maglia",
  "buy": "Ordina",
  "soldOut": "Sold out"
}
