{
  "menu": [
    { "label": "主题", "route": "project", "key": "project", "isPrimary": true, "name": "PROJECT" },
    { "label": "球队", "route": "team", "key": "team", "isPrimary": true, "name": "TEAM" },
    { "label": "贊助", "route": "sponsor", "key": "sponsor", "name": "SPONSOR" },
    {
      "label": "艺术家",
      "route": "artists",
      "key": "artists",
      "isPrimary": true,
      "name": "ARTISTS"
    },
    { "label": "精品", "route": "shop", "key": "shop", "isPrimary": true, "name": "SHOP" },
    { "label": "票", "route": "tickets", "key": "tickets", "name": "TICKETS" },
    { "label": "管理人员", "route": "club", "key": "club", "name": "CLUB" },
    { "label": "新闻室", "route": "press", "key": "press", "name": "PRESS" },
    { "label": "联系", "route": "disclaimer", "key": "contacts", "name": "CONTACTS" }
  ],
  "backToList": "Back to products",
  "persoPlaceHolder": "Name and/or Number",
  "personalize": "Customize your jersey",
  "buy": "Order",
  "soldOut": "Sold out"
}
