{
  "menu": [
    {
      "label": "project",
      "route": "project",
      "key": "project",
      "isPrimary": true,
      "name": "PROJECT"
    },
    { "label": "team", "route": "team", "key": "team", "isPrimary": true, "name": "TEAM" },
    { "label": "sponsor", "route": "sponsor", "key": "sponsor", "name": "SPONSOR" },
    {
      "label": "artists",
      "route": "artists",
      "key": "artists",
      "isPrimary": true,
      "name": "ARTISTS"
    },
    { "label": "shop", "route": "shop", "key": "shop", "isPrimary": true, "name": "SHOP" },
    { "label": "tickets", "route": "tickets", "key": "tickets", "name": "TICKETS" },
    { "label": "club", "route": "club", "key": "club", "name": "CLUB" },
    { "label": "press", "route": "press", "key": "press", "name": "PRESS" },
    { "label": "edition", "route": "edition", "key": "edition", "name": "EDITION" },
    { "label": "contacts", "route": "disclaimer", "key": "contacts", "name": "CONTACTS" }
  ],
  "backToList": "Back to products",
  "persoPlaceHolder": "Name and/or Number",
  "personalize": "Customize your jersey",
  "buy": "Order",
  "soldOut": "Sold out"
}
