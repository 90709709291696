import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const getServerUrl = () => process.env.SERVER_URL;

const getApiUrl = () => `${getServerUrl()}${process.env.API_URL}`;

const getApiGet = () => `${getApiUrl()}/collections/get/`;

const getApiToken = () => process.env.API_TOKEN;

const getTutoCampiToken = () => process.env.TUTOCAMPI_TOKEN;

const getCurrentYear = () => process.env.CURRENT_YEAR;

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const getRouteWithLocale = (route) => `/${window.LOCALE_VELASCA || 'en'}/${route}`;

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export {
  getApiUrl,
  getApiGet,
  getServerUrl,
  getRouteWithLocale,
  getTutoCampiToken,
  getApiToken,
  getCurrentYear,
  ScrollToTop,
};
